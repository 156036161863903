import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
const Header = ({ siteTitle }) => (
  <header
    style={{
      position: "absolute",
      zIndex: 30,
      width: "100%",
    }}
  >
    <div className="top-bar">
      <ul id={"top-bar-nav"}>
        <li>
          <a href={"#contact"}>Contact: 07562 716 088</a>
        </li>
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
